<template>
  <van-skeleton title :row="7" :loading="loading">
    <div class="goodsQr">
      <van-nav-bar
        title="物料二维码"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
        safe-area-inset-top
        :border="false"
      />
      <div class="goods-main">
        <div class="head-box">
          <div class="head-img">
            <img :src="state.picUrl" alt="" />
          </div>
          <div class="flex-box">
            <div style="flex:1">
              <div class="title">{{ state.materialName }}</div>
              <van-tag plain type="danger" size="20" round>热门</van-tag>
              <div class="price-info">
                <span>￥</span>
                <span class="price">{{ state.sellingPrice }}</span>
                <span class="nums">已售{{ state.nums }}</span>
              </div>
            </div>
            <div class="qr-code" style="align-self: center;">
              <img :src="state.qrCodeUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-skeleton>
</template>

<script setup>
import { Toast } from "vant";
import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getShopMaterialDetail } from "../../api/common";
const router = useRouter();
const route = useRoute();
const store = useStore();
const materialId = route.query.id;
const token = computed(() => store.getters.token);
const materialStockId = computed(() => store.getters.materialId);
const loading = ref(true);
const state = reactive({
  nums: "",
  conversionRate: "",
  materialCode: "",
  rotationPicture: [],
  brandName: "",
  categoryName: "",
  details: "",
  itemNo: "",
  materialName: "",
  specs: "",
  properties: [],
  salesUnitName: "",
  purchasingUnitName: "",
  sellingPrice: "",
  qrCodeUrl: "",
  collocationList: [],
  picUrl: "",
});
const onClickLeft = () => {
  router.back();
};
//生成三位随机数
for (var i = 0; i < 3; i++) {
  state.nums += Math.floor(Math.random() * 10);
}
const loadGoodsDetails = async () => {
  loading.value = true;
  Toast.loading({ duration: 300, message: "加载中" });
  console.log(route.query.id)
  let data = await getShopMaterialDetail({
    token: token.value,
    materialId: route.query.id,
    materialStockId: materialStockId.value,
  });
  state.materialCode = data.materialCode;
  state.conversionRate = data.conversionRate;
  state.rotationPicture = data.rotationPicture;
  state.brandName = data.brandName;
  state.categoryName = data.categoryName;
  state.details = data.details;
  state.itemNo = data.itemNo;
  state.materialName = data.materialName;
  state.specs = data.specs;
  state.properties = data.properties;
  state.salesUnitName = data.salesUnitName;
  state.purchasingUnitName = data.purchasingUnitName;
  state.sellingPrice = data.sellingPrice;
  state.qrCodeUrl = data.qrCodeUrl;
  state.collocationList = data.collocationList;
  state.picUrl = data.picUrl;
  Toast.clear();
  loading.value = false;
};
loadGoodsDetails();
</script>

<style lang="scss" scoped>
.goodsQr {
  text-align: left;
  .goods-main {
    background: #fff;
    display: flex;
    justify-content: center;
    margin-top: 42px;
    .head-box {
      background: #fff;
      .head-img {
        img {
          width: 240px;
          height: 204px;
        }
      }
      .title {
        color: #171725;
        font-weight: 500;
        font-size: 16px;
      }
      .flex-box {
        margin: 12px 0;
        display: flex;
        width: 240px;
        justify-content: space-between;
        .price-info {
          span:nth-of-type(1) {
            font-size: 14px;
            color: red;
          }
          span:nth-of-type(2) {
            font-size: 20px;
            color: red;
          }
          span:nth-of-type(3) {
            color: #696974;
            font-size: 12px;
          }
        }
        .qr-code {
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
    }
  }
}
</style>
